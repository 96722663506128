

.container {
  display: flex;
  min-height: 512px;
  padding: 32px;
  margin: 40px;
  margin-top: 150px;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 24px;
  background: #FFF;
  box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
  background-image: url("../../images/hero.png");
  background-repeat: no-repeat;
  background-position: bottom right 20%;

  @media (max-width: 1300px) {
    background-position: bottom right;
  }

  @media (max-width: 1024px) {
    background-image: none;
    flex-direction: column;
  }

  .text {
    justify-content: flex-end;
    max-width: 736px;
    gap: 20px;
    //padding: 0 30px;
    h1 {
      color: #222;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px; /* 150% */
      @media (max-width: 768px) {
        color: #222;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 150% */
      }
    }
    .subtext {
      margin-bottom: 20px;
      p {
        margin-top: 16px;
        color: #222;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 150% */
        @media (max-width: 768px) {
          font-size: 14px;
          font-style: normal;
          line-height: 20px; /* 142.857% */
        }
      }
      .mt {
        margin-top: 35px;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;
      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 8px;
        button {
          width: 100%;
        }
      }
      button {
        font-size: 24px;
        padding: 16px 24px;
        border-radius: 16px;
        gap: 16px;
        img {
          width: 24px;
          height: 24px;
        }
        @media (max-width: 1440px) {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 144.444% */
        }
      }
    }
  }
  .image {
    @media (min-width: 1025px) {
        display: none;
    }
    img {
      max-height: 693px;
      width: auto;
      max-width: 100%;
      height: auto;
    }
    @media (max-width: 1150px) {
      margin-bottom: 30px;
    }
  }
  .all {
    height: auto;
    right: 0;
    position: absolute;
    top: 94px;
    width: 640px;
    max-width: 100%;
    @media (max-width: 1150px) {
      display: none;
    }
  }
}