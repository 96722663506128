

.box {
  display: flex;
  padding: 24px;
  min-width: 320px;
  max-width: 357px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex: 1 0 30%;
  border-radius: 24px;
  background: var(--white-black-white, #FFF);
  box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
  align-self: stretch;
  .icon {
    max-width: 80%;
    max-height: 120px;
    width: auto;
    height: auto;
  }
  h2 {
    color: #222;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px; /* 150% */
    margin: 0;
  }
  p {
    color: #888;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */
    margin: 0;
  }
  a {
    color: #222;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin: 0;
    margin-top: 16px;
    text-decoration: none;
  }
  iframe {
    min-height: 230px;
  }
}
