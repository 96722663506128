.iframeContainer {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    article {
        flex: 1;
        max-width: 1000px;
    }
}