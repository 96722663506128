

.container {
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
  .teamImage {
    width: 100%;
    height: 100%;
  }
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 0 160px;
    @media (max-width: 768px) {
      padding: 0 80px;
    }
    @media (max-width: 450px) {
      padding: 0 40px;
    }
  }
  h1 {
    color: #000;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px; /* 150% */
    @media (max-width: 768px) {
      color: #000;
      align-self: flex-start;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
    }
  }
  p {
    color: #222;
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 150% */
  }
  .section > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .members {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    flex-wrap: wrap;
  }
  .vision {
    max-width: 548px;
  }
}