

.container {
  display: flex;
  padding: 120px 160px;
  @media (max-width: 1430px) {
    padding: 80px 40px;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  .title {
    text-align: center;
  }
  .button {
    display: flex;
    padding: var(--Sizing-M, 16px) 24px;
    justify-content: center;
    align-items: center;
    gap: var(--Sizing-M, 16px);
  }
}