

.container {
  display: flex;
  padding: 120px 160px;
  @media (max-width: 1430px) {
    padding: 80px 40px;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  .title {
    color: #670AC1;
    text-align: center;
  }
  .button {
    display: flex;
    padding: var(--Sizing-M, 16px) 24px;
    justify-content: center;
    align-items: center;
    gap: var(--Sizing-M, 16px);
  }
  .box {
    @media (max-width: 500px) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    @media (max-width: 374px) {
      h2 {
        font-size: 18px;
      }
    }
  }
}