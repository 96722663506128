

.text {
  padding: 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--Sizing-M, 16px);
  background: #222;
  color: var(--white-black-white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}